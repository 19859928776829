import {
    customerServiceAgentFlatPng,
    webDevelopmentTwoColopng,
    teamMeetingPng,
    developmentPng,
    cloudComputingFlatPng,
    QA,
    us,
  } from "../assets/img";

  // data.js

export const aboutData = {
  about__id: "About Us",
  about__title: "Our Journey and Commitment",
  about__subtitle:
    "Discover the story behind Pervasive Services, our dedicated team, and our unwavering commitment to delivering excellence in every service.",
  about__button: "See More",
  about__img: us, // You may need to replace this with the actual image path
  aboutUsContent: {
    title: "Welcome to Pervasive Services",
    subtitle:
      "At Pervasive Services, we're not just a company; we're a story of passion, dedication, and digital innovation. Founded with a vision to transform ideas into reality, we embark on a journey to redefine the landscape of web app and software development.",
    vision: {
      title: "Our Vision",
      content: [
        "Unlocking Digital Possibilities",
        "We believe in unlocking the boundless potential of the digital realm. Our mission is to empower businesses and individuals by providing world-class software solutions that transcend expectations.",
      ],
    },
    soloProfessional: {
      title: "The Solo Professional",
      content: [
        "Pervasive Services is a startup powered by a singular force – a seasoned professional with a wealth of industrial experience. As the sole architect behind the scenes, our founder brings a unique perspective and a commitment to excellence in every project.",
      ],
    },
    craftingExcellence: {
      title: "Crafting Excellence",
      content: [
        "From Vision to Reality",
        "Our commitment extends beyond lines of code; it's a promise to craft excellence from the inception of an idea to the final product. Pervasive Services is where innovation meets precision, and every project is a testament to our unwavering dedication.",
      ],
    },
    exploreHistory: {
      title: "Explore Our History",
      content: [
        "A Story Unfolding",
        "Our journey is more than a timeline; it's a narrative painted with moments of innovation, challenges conquered, and milestones achieved. Explore the chapters of our history as we continue to push boundaries and shape the future of software development.",
      ],
    },
    pervasiveExperience: {
      title: "Join the Pervasive Experience",
      content: [
        "Beyond the Conventional",
        "Pervasive Services invites you to be part of a unique experience where conventional norms are surpassed, and digital horizons are explored. This is not just about us; it's an invitation to join a community that values creativity, expertise, and the relentless pursuit of excellence.",
      ],
    },
  },
};

export const services__Data = [
  {
    "id": 1,
    "icon": teamMeetingPng,
    "title": "Software Development Services",
    "content": {
      "shortDescription": "Comprehensive development services for Custom Software, Web, and Mobile Apps tailored to your unique business needs.",
      "longDescription": "We deliver end-to-end software development services, leveraging Spring Boot, React, Angular, Next.js, and Node.js to build custom solutions. From requirements gathering to deployment, we ensure high performance, scalability, and seamless integration of technologies, bringing your digital vision to life. Our services prioritize exceptional user experience and robust performance across platforms."
    }
  },
  {
    "id": 2,
    "icon": webDevelopmentTwoColopng,
    "title": "Software as a Service (SAAS) Solutions",
    "content": {
      "shortDescription": "Scalable and secure SaaS solutions tailored for industry-specific needs.",
      "longDescription": "Our SaaS offerings include both pre-developed and custom solutions designed to address your business challenges. Utilizing technologies like Node.js, Docker, and GCP/Azure, we create secure, scalable, and cost-effective solutions. From product design to deployment, we ensure your SaaS platform delivers measurable business value."
    }
  },
  {
    "id": 3,
    "icon": cloudComputingFlatPng,
    "title": "Cloud Application Development",
    "content": {
      "shortDescription": "Empowering businesses with scalable and flexible cloud solutions.",
      "longDescription": "Specializing in cloud-native applications, we utilize GCP, Azure, and other platforms to deliver solutions that scale with your needs. Whether you're migrating existing systems or building cloud-native apps, we ensure optimized performance, efficient data management, and cost-effective operations. Our expertise in microservices architecture ensures agility and robustness in cloud environments."
    }
  },
  {
    "id": 4,
    "icon": developmentPng,
    "title": "Software Integration",
    "content": {
      "shortDescription": "Unlock efficiency by seamlessly connecting diverse software systems.",
      "longDescription": "We specialize in integrating disparate systems like CRM, ERP, and third-party APIs to create a cohesive ecosystem. With expertise in Kafka, Message Queues, and other tools, we enable real-time data flow and operational efficiency. Our solutions bridge technology gaps, creating synchronized and scalable systems tailored to your business processes."
    }
  },
  {
    "id": 5,
    "icon": QA,
    "title": "Software Testing and Quality Assurance",
    "content": {
      "shortDescription": "Ensuring performance and reliability through rigorous testing processes.",
      "longDescription": "We provide comprehensive testing services, covering functional, performance, security, and usability aspects of your software. Using automated and manual testing methodologies, our QA experts ensure your applications meet the highest quality standards. With a focus on proactive issue resolution, we enhance user satisfaction and business continuity."
    }
  },
  {
    "id": 6,
    "icon": customerServiceAgentFlatPng,
    "title": "IT Support Services",
    "content": {
      "shortDescription": "End-to-end IT support for reliable and efficient system operations.",
      "longDescription": "Our IT Support services encompass server management, monitoring, error handling, and bug resolution. Leveraging expertise in Linux, Redis, and cloud environments, we ensure system reliability and uptime. Whether addressing critical errors or maintaining infrastructure, our services let you focus on growth while we handle your IT challenges."
    }
  }
];
  