import React from "react";
import Footer from "../componets/Footer";
import Header from "../componets/Header";

const TermsAndConditions = () => {
  return (
    <>
      <Header />

      <div className="TermsAndConditions-container">
        <h1>Terms and Conditions</h1>
        <div className="TermsAndConditions-content">
          <h2>1. Acceptance of Terms</h2>
          <p>
            By using our SaaS service, you agree to comply with and be bound by
            these Terms and Conditions.
          </p>

          <h2>2. Definitions</h2>
          <p>
            - "Company" refers to Pervasive Services. <br />
            - "Service" refers to the SaaS service provided by the Company.{" "}
            <br />
            - "User" refers to any individual or entity using the Service.{" "}
            <br />- "Content" refers to data, information, or materials provided
            or used with the Service.
          </p>

          <h2>3. Use of Service</h2>
          <p>
            - Users must be at least 18 years old to use the Service. <br />
            - Users are responsible for their account credentials and any
            actions taken with their account. <br />- Users may not use the
            Service for any illegal or unauthorized purposes.
          </p>

          <h2>4. Privacy Policy</h2>
          <p>
            - The company's Privacy Policy governs the collection and use of
            personal information.
          </p>

          <h2>5. User Content</h2>
          <p>
            - Users retain ownership of their Content. <br />
            - The Company may access, use, or disclose User Content as necessary
            to provide the Service. <br />- Users are responsible for their
            Content's legality and compliance with these Terms.
          </p>

          <h2>6. Payment and Billing</h2>
          <p>
            - Users are responsible for payment of all fees associated with the
            Service. <br />- The Company may change pricing or introduce new
            fees with prior notice.
          </p>

          <h2>7. Termination</h2>
          <p>
            - The Company may terminate or suspend a User's account for
            violations of these Terms. <br />
            - Users may terminate their account at any time. <br />- Upon
            termination, User Content may be deleted.
          </p>

          <h2>8. Disclaimer of Warranties</h2>
          <p>
            - The Service is provided "as is" without warranties. <br />- The
            Company does not guarantee that the Service will be error-free.
          </p>

          <h2>9. Limitation of Liability</h2>
          <p>
            - The Company is not liable for any damages or losses. <br />- Users
            use the Service at their own risk.
          </p>

          <h2>10. Governing Law</h2>
          <p>
            - These Terms and Conditions are governed by the laws of India.
          </p>

          <h2>11. Contact Information</h2>
          <p>
            - For questions about these Terms, contact us at:
            <br />
            Email: info.pervasive-services@gmail.com <br />
            Phone: +91 9158713568
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
