import React from "react";
import Footer from "../componets/Footer";
import Header from "../componets/Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <div className="PrivacyPolicy-container">
        <h1>Privacy Policy</h1>
        <div className="PrivacyPolicy-content">
          <h2>1. Data Collection</h2>
          <p>
            We collect and process data that you provide while using our app
            development and support services, including personal data (e.g.,
            name, email address) and non-personal data (e.g., usage statistics).
          </p>

          <h2>2. User Information</h2>
          <p>
            We may gather user data related to app interactions, usage, and
            support requests for improving our services. This includes device
            information, IP address, and app activity logs.
          </p>

          <h2>3. Legal Basis for Data Processing</h2>
          <p>
            In accordance with GDPR, we process your data based on your consent,
            the necessity to perform a contract, compliance with legal
            obligations, or legitimate interests.
          </p>

          <h2>4. Data Usage</h2>
          <p>
            Your data is used to enhance your app development experience,
            troubleshoot issues, provide dedicated support, and ensure
            compliance with applicable laws.
          </p>

          <h2>5. Data Protection</h2>
          <p>
            We implement strict security measures, including encryption and
            secure access protocols, to protect your data and maintain its
            confidentiality.
          </p>

          <h2>6. Data Sharing</h2>
          <p>
            We may share your data with trusted partners and service providers
            solely for app development and support purposes. We ensure that
            these parties comply with applicable privacy laws.
          </p>

          <h2>7. Cookie Usage</h2>
          <p>
            We utilize cookies to personalize your experience, analyze user
            behavior, and improve app functionality. You can manage your cookie
            preferences through your browser settings.
          </p>

          <h2>8. Data Retention</h2>
          <p>
            We retain your data for the duration necessary to deliver app
            development and support services, comply with legal obligations, and
            resolve disputes.
          </p>

          <h2>9. User Rights</h2>
          <p>
            You have the right to access, rectify, delete, and port your
            personal data. You can also withdraw consent or object to data
            processing. To exercise these rights, contact us at +91 9158713568
          </p>

          <h2>10. Child Privacy</h2>
          <p>
            We do not knowingly collect personal data from children under the
            age of 13 (or 16 in Europe, as applicable). If you believe we have
            collected such data, please contact us immediately.
          </p>

          <h2>11. Cross-Border Data Transfers</h2>
          <p>
            Your data may be transferred to and processed in countries outside
            of your jurisdiction. We ensure that such transfers comply with
            applicable laws, including GDPR's Standard Contractual Clauses.
          </p>

          <h2>12. Policy Updates</h2>
          <p>
            We may revise this policy to reflect changes in our app development
            and support services. Updated versions will be published on this
            page with a revised "Last Updated" date.
          </p>

          <h2>13. Contact Details</h2>
          <p>
            For inquiries or concerns regarding our Privacy Policy, please reach
            out to us at info.pervasive.services@gmail.com.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
