import { logo } from "../../assets/img";
import React, { useState } from "react";
import { navLinks } from "../../data/content";
import { useTheme } from "../../ThemeContext"; // Import your useTheme hook
// import { FiMoon, FiSun } from "react-icons/fi"; // Import the moon and sun icons from react-icons

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Header() {
  const { isDarkMode, toggleTheme } = useTheme();
  const navigate = useNavigate();

  const divStyle1 = {
    display: "inline-block",
    width: "30%",
  };
  const divStyle2 = {
    display: "inline-block",
    width: "70%",
    fontSize: "20px",

    fontFamily: "Comic Sans MS", // Set the font family to "Comic Sans MS"
    whiteSpace: "nowrap", // This ensures that text doesn't wrap to the next line
    position: "relative", // Enable relative positioning
    top: "-2.5px", // Move the text 0.05 pixels up
  };

  const containerStyle = {
    display: "flex", // Display children in a row
    alignItems: "center", // Center children vertically
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleClick = (event, path) => {
    event.preventDefault();
    navigate(path);
    // Add your custom logic for navigation
    // For example, you can use react-router-dom to navigate programmatically
    // history.push(path);
  };

  return (
    // <!--========== HEADER ==========-->

    <header
      className={`l-header ${mobileMenuOpen ? "show-menu" : ""}`}
      id="header"
    >
      <nav className="nav bd-container">
        <Link
          to="/"
          className="nav__logo"
          onClick={(event) => handleClick(event, `/`)}
        >
          <div style={containerStyle}>
            <div style={divStyle1}>
              <img src={logo} alt={logo} width={50} height={50} className="" />
            </div>
            <div style={divStyle2}>Pervasive Services</div>
          </div>
        </Link>

        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list">
            {navLinks.map((nav, i) => (
              <li
                key={nav.id}
                className={`nav__item font-poppins font-normal cursor-pointer text-[16px] ${
                  i === navLinks.length - 1 ? "mr-0 font-bold" : "mr-10"
                } text-white mr-10`}
              >
                {/* <a className="nav__link" href={`/${nav.id}`}>
                  {nav.title}
                </a> */}
                <Link
                  to={`/${nav.id}`}
                  className="nav__link"
                  onClick={(event) => handleClick(event, `/${nav.id}`)}
                >
                  {nav.title}
                </Link>
              </li>
            ))}

            {/* <li
              className={`theme-toggle ${isDarkMode ? "dark-mode" : ""}`}
              onClick={toggleTheme}
            >
              {isDarkMode ? (
                <FiMoon size={16} className="theme-toggle-icon" color="white" />
              ) : (
                <FiSun size={16} className="theme-toggle-icon" color="yellow" />
              )}
            </li> */}
          {/* bx-toggle-left */}
            <i

              className={`bx ${
                isDarkMode ? "bx-toggle-right" : "bx-toggle-left"
              } theme-toggle`}
              id="theme-button"
              onClick={toggleTheme}
            ></i>
            {/* <button> */}
            {/* <a href="/contact" className="button2 ">
              Let's Talk !
              </a> */}

            <Link
              to={`/contact`}
              className="button2"
              onClick={(event) => handleClick(event, `/contact`)}
            >
              Let's Talk !{/* {nav.title} */}
            </Link>

            {/* </button> */}
          </ul>
        </div>

        <div
          className={`nav__toggle ${mobileMenuOpen ? "active-menu" : ""}`}
          id="nav-toggle"
          onClick={toggleMobileMenu}
        >
          <i className="bx bx-menu"></i>
        </div>

        {/* </div> */}
      </nav>
    </header>
  );
}

export default Header;
